import { NgModule } from '@angular/core';
import { InfoToggleComponent } from './info-toggle.component';

@NgModule({
	imports: [
	],
	declarations: [
		InfoToggleComponent
	],
  exports: [
    InfoToggleComponent
  ]
})
export class InfoToggleModule {}
