import { Component } from '@angular/core';

@Component({
	selector: 'info-toggle',
	templateUrl: 'info-toggle.component.html',
})

export class InfoToggleComponent {

  infoToggleState = false;

  get isOn() {
    return this.infoToggleState;
  }

  public onInfoToggleClicked() {
    this.infoToggleState = !  this.infoToggleState;
  }
}
