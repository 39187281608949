import localeDe from '@angular/common/locales/de';

import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';

import { InfoToggleModule } from './components/info-toggle/info-toggle.module';

registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [AppComponent],
  imports: [
    ReactiveFormsModule,
    BrowserAnimationsModule,
    // PrimeNg
    ButtonModule,
    DropdownModule,
    SelectButtonModule,
    SliderModule,
    // Eigene Module
    InfoToggleModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
